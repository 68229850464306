
import {defineComponent} from 'vue';
import { MonthPicker } from 'vue-month-picker'
import moment from 'moment';

export default defineComponent({
  components: {
    MonthPicker
  },
  name: 'Job',
  data() {
    return {
      showCalendar: false,
      job: {
        contract_numbers: '',
        year: '',
        month: '',
        name: '',
      },
      date: {
				from: null,
				to: null,
				month: null,
				year: null
			},
      error: false,
      errorMessage: null,
      loading: false,
      showInput: false,
      projectId: '',
      masks: {
      input: 'MMMM',
      title: 'YYYY',
    },
    }
  },
  watch: {
    job: {
      handler(val){
        this.defaultName();
      },
      deep: true
    },
    date: function(val) {
      if(val) {
        this.defaultName();
      }
    }
  },
  methods: {
    selectDate(date) {
      this.date = date;
      if(this.date.month) {
        this.showCalendar = !this.showCalendar;
      }
    },
    defaultName() {
      if(this.job.contract_numbers && this.date.month) {
        const arrayNumbers = this.job.contract_numbers.split(',')
        this.job.name = `${arrayNumbers[0]}_${moment(this.date.from).format('YYMM')}_${moment().format('DDMMYY')}`
      }
    },

    saveJob() {
      if (!this.loading) {
        this.loading = true;
        this.job.year = moment(this.date.from).format('YYYY');
        this.job.month = moment(this.date.from).format('MM');
        this.$store.dispatch('saveJob', this.job).then(() => {
          this.loading = false;
          this.$router.push('/jobs')
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          this.errorMessage = error.response.data.error.message;
        })
      }
    },
  },
});
